import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/399929862"
    notesSrc="https://www.bible.com/events/7137750"
    notesText="Sermon Notes"
    bibleGroupSrc="https://www.docdroid.net/BXziM16/bible-group-homework-3-22.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="A Pastor's Prayer for his People - When People Pray" />
  </Layout>
)

export default SermonPost
